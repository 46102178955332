/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useRef, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Button, Grid, Go, Image, SwiperCarousel } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import frost from "~assets/videos/frost-wipe.webm";
import frostSafari from "~assets/videos/frost-wipe.mp4";
import frostTablet from "~assets/images/atf-frost-tablet.png";
import frostMobile from "~assets/images/atf-frost-mobile.png";

const SWIPER_PAGINATION_HEIGHT = 20;

const Wrapper = styled.div`
  position: relative;
`;

const DesktopContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  grid-column: 1 / -1;
`;

// const TabletContainer = styled.div`
//   height: 100%;
//   display: flex;
//   white-space: nowrap;
//   overflow-x: scroll;
//   grid-column: 1 / -1;
//   border: 3px solid blue;
// `;

const MobileContainer = styled.div`
  grid-column: 1 / -1;
`;

// const TabletScrollbar = styled.div`
//   position: relative;
//   grid-column: 5 / span 4;
//   padding: 2rem;
//   // background: black;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   column-gap: 6px;

//   &:before {
//     content: "";
//     width: 10%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     background: blue;
//   }
// `;

// const TabletScrollbarPart = styled.span`
//   height: 4px;
//   border-radius: 50px;
//   background: black;
//   flex-grow: 1;
// `;

const Item = styled.article`
  height: 100%;
  padding: 32px 16px calc(32px + ${SWIPER_PAGINATION_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;

  ${MEDIA_QUERIES.tablet} {
    // flex: 0 0 100%;
    padding: 40px 85px 48px;
  }

  ${MEDIA_QUERIES.desktop} {
    padding: 0 48px 74px;
    row-gap: 8px;
    flex-grow: 1;

    ${MEDIA_QUERIES?.hoverable} {
      &:hover {
        cursor: pointer;
        figure {
          transform: scale(1.1);
        }
      }
    }
  }
`;

const ItemHeading = styled.h4`
  text-align: center;
  text-transform: uppercase;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;

  ${MEDIA_QUERIES.desktop} {
    order: 2;
  }
`;

const ItemImage = styled.figure`
  transition: transform 0.3s var(--cubic-easing);
  display: relative;
  aspect-ratio: 1;
  transform: scale(1);
  width: 75%;

  ${MEDIA_QUERIES?.hoverable} {
    &:hover {
      transform: scale(1.1);
    }
  }

  ${MEDIA_QUERIES.tablet} {
    width: 100%;
    padding-bottom: 24px;
  }

  ${MEDIA_QUERIES.desktop} {
    order: 1;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${MEDIA_QUERIES?.hoverable} {
      &:hover {
        transform: none;
      }
    }
  }
`;

const FrostContainer = styled.figure`
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  ${MEDIA_QUERIES.desktop} {
    transition: opacity 2.5s var(--cubic-easing) 300ms;
  }
`;

const Video = styled.video`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const ThreeColBanner = ({ data: { items } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const { isMobile, isDesktop, isLargeMobile, isTouch, isTablet } =
    useBreakpoint();

  const videoRef = useRef(null);

  const isTabletOnly = !isDesktop && !isLargeMobile;

  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [asCarousel, setAsCarousel] = useState(isMobile);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setAsCarousel(isTouch);
  }, [isTouch]);

  useEffect(() => {
    if (!videoRef?.current || typeof window === `undefined`) {
      return () => {};
    }

    const video = videoRef.current;
    let cancelRequestAnimationFrame = false;

    const onTimeUpdate = () => {
      if (video.currentTime >= 2.9) {
        video.pause();
        setIsVideoPaused(true);
      } else if (!cancelRequestAnimationFrame) {
        window.requestAnimationFrame(onTimeUpdate);
      }
    };

    const timeoutId = setTimeout(() => {
      cancelRequestAnimationFrame = true;
    }, 6000);

    window.requestAnimationFrame(onTimeUpdate);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [videoRef]);

  // ---------------------------------------------------------------------------
  // render

  const getItemJsx = (item) => {
    const { title, image, button, backgroundColor, fontColor } = item || {};

    return (
      <Item
        css={css`
          background: ${backgroundColor?.hex
            ? backgroundColor.hex
            : `var(--color-white)`};

          color: ${fontColor?.hex ? fontColor.hex : `var(--color-white)`};
        `}
      >
        <ItemContent>
          {title && (
            <ItemHeading className={isTablet ? `h1` : `d3`}>
              {title}
            </ItemHeading>
          )}

          {isTabletOnly && button?.text && button?.link && (
            <Go
              _css={css`
                padding-bottom: 24px;
              `}
              to={button.link}
            >
              <Button text={button?.text} _css={css``} />
            </Go>
          )}
        </ItemContent>

        {image?.asset?.gatsbyImageData && (
          <ItemImage>
            <Image
              css={css`
                aspect-ratio: 1;
                ${MEDIA_QUERIES.desktop} {
                  max-width: 370px;
                }
              `}
              image={image}
            />
          </ItemImage>
        )}

        {isLargeMobile && button?.text && button?.link && (
          <Go
            _css={css`
              width: 100%;
              padding-bottom: 24px;
            `}
            to={button.link}
          >
            <Button text={button?.text} _css={css``} />
          </Go>
        )}
      </Item>
    );
  };

  return (
    <Wrapper>
      <Grid
        _css={css`
          padding: 0 !important;

          ${MEDIA_QUERIES.desktop} {
            width: 100%;
            max-width: none;
          }
        `}
      >
        {/* desktop */}
        {isDesktop && (
          <DesktopContainer>
            {items?.[0] &&
              items.map((item) => {
                let i;

                return (
                  <Go
                    _css={css`
                      flex-grow: 1;
                    `}
                    key={`three-col-banner-desktop-item${item?._key}`}
                    to={item?.button.link}
                  >
                    {getItemJsx(item)}
                  </Go>
                );
              })}
          </DesktopContainer>
        )}

        {/* tablet */}
        {/* custom tablet view with a custom scrollbar instead of a carousel */}
        {/* didn't have enough time to get the custom scroll bar to work */}
        {/* {!asCarousel && !isDesktop && (
          <>
            <TabletContainer ref={tabletContainerRef}>
              {items?.[0] &&
                items.map((item) => {
                  const {
                    _key,
                    title,
                    image,
                    button,
                    backgroundColor,
                    fontColor
                  } = item || {};
                  return (
                    <Item
                      key={`three-col-banner-item${_key}`}
                      css={css`
                        background: ${backgroundColor?.hex
                          ? backgroundColor.hex
                          : `var(--color-white)`};

                        color: ${fontColor?.hex
                          ? fontColor.hex
                          : `var(--color-white)`};
                      `}
                    >
                      <ItemContent>
                        {title && (
                          <ItemHeading className={isTabletOnly ? `h1` : `d3`}>
                            {title}
                          </ItemHeading>
                        )}

                        {button?.text && button?.link && (
                          <Go _css={css``} to={button.link}>
                            <Button text={button?.text} _css={css``} />
                          </Go>
                        )}
                      </ItemContent>

                      {image?.asset?.gatsbyImageData && (
                        <ItemImage>
                          <Image image={image} />
                        </ItemImage>
                      )}
                    </Item>
                  );
                })}
            </TabletContainer>

            <TabletScrollbar>
              {items?.[0] &&
                items.map((item) => (
                  <TabletScrollbarPart
                    key={`three-col-banner-scrollbar-part-${item?._key}`}
                  />
                ))}
            </TabletScrollbar>
          </>
        )} */}

        {/* mobile carousel */}
        {asCarousel && (
          <MobileContainer>
            <SwiperCarousel
              id={`carousel-swiper-desktop-${uuidv4()}`}
              css={css`
                height: auto;

                .swiper-horizontal > .swiper-pagination-bullets,
                .swiper-pagination-bullets.swiper-pagination-horizontal {
                  bottom: 32px;
                }

                .swiper-pagination-bullet {
                  background-color: var(--color-black);
                  opacity: 1;
                }

                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                  background-color: var(--color-white);
                }

                ${MEDIA_QUERIES.tablet} {
                  .swiper-pagination-bullet {
                    width: 74px;
                    height: 4px;
                    border-radius: 4px;
                  }
                }
              `}
              options={{
                centeredSlides: true,
                loopAdditionalSlides: 4,
                slidesPerView: 1,
                draggable: true,
                pagination: { clickable: true }
              }}
              slides={items.map((item) => {
                let i;
                return (
                  <React.Fragment
                    key={`three-col-banner-carousel-item-${item?._key}`}
                  >
                    {getItemJsx(item)}
                  </React.Fragment>
                );
              })}
            />
          </MobileContainer>
        )}

        <FrostContainer
          css={css`
            ${MEDIA_QUERIES.desktop} {
              ${isVideoPaused && `opacity: 0`}
            }
          `}
        >
          <img
            css={css`
              height: 100%;
              width: 100%;
              z-index: 1;
              position: absolute;
              object-fit: cover;
              object-position: center;
              pointer-events: none;

              ${MEDIA_QUERIES.desktop} {
                display: none;
              }
            `}
            src={isTablet ? frostTablet : frostMobile}
            alt="Banner frost effect"
          />

          {/* {useFrostVideo && ( */}
          <Video ref={videoRef} muted autoPlay>
            <source src={frostSafari} type="video/mp4;codecs=hvc1" />
            <source src={frost} type="video/webm" />
          </Video>
          {/* )} */}
        </FrostContainer>
      </Grid>
    </Wrapper>
  );
};

export default ThreeColBanner;

export const query = graphql`
  fragment ThreeColBannerFragment on SanityThreeColBanner {
    _type

    items {
      _key
      backgroundColor {
        hex
        title
      }

      fontColor {
        hex
        title
      }
      title

      image {
        altText
        asset {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }

      button {
        text
        link
      }
    }
  }
`;
